@tailwind base;
@tailwind components;
@tailwind utilities;

@import "partials/_font";
@import "partials/_form";

body{
  font-family: "Source Sans Pro", Arial, sans-serif;
}

#brand{
  @apply uppercase;
  @apply text-sky-600;
  @apply text-3xl;
  @apply font-bold;
  @apply sm:pl-0;
}


@media screen and (max-width: 750px) {
  .mobile-nav{
    justify-content: center;
  }
}

h1{
  @apply text-gray-700;
  @apply font-light;
  @apply text-4xl;
  @apply ml-6;
  @apply py-4;
}
h2 {
  @apply text-2xl;
  @apply text-gray-700;
  @apply text-center;
  @apply py-4;
  @apply font-light;
}

h3{
  @apply text-4xl;
  @apply font-light;
  @apply text-gray-800;
  @apply uppercase;
  @apply py-10;
  @apply tracking-tight;
  @apply text-center;
  @apply sm:text-5xl;
}

h3:after {
  background: none repeat scroll 0 0 #0284C7;
  bottom: -20px;
  content: "";
  display: block;
  height: 2px;
  position: relative;
  width: 100px;
  text-align: center;
  margin: auto;
}

nav{
  @apply mb-4;
  @apply lg:mb-0;
  @apply text-gray-600;
  @apply sticky;
  @apply top-0;
  @apply z-10;
}

#establishment{
  @apply text-5xl;
  @apply font-extralight;
  @apply text-gray-800;
  @apply uppercase;
  @apply py-8;
  @apply tracking-tight;
  @apply sm:text-6xl;
}

#establishment:after {
  background: none repeat scroll 0 0 #0284C7;
  bottom: -20px;
  content: "";
  display: block;
  height: 2px;
  position: relative;
  width: 100px;
  text-align: center;
  margin: auto;
}

.spacer{
 padding:20px 0 60px 0;
}

#main-img{
  @apply pb-8;
  @apply sm:pb-0;
}

main p{
  @apply text-xl;
  @apply font-normal;
}

.justify-text{
  @apply text-justify;
  @apply text-gray-600;
  @apply leading-relaxed;
}

.center-text{
  @apply text-center;
  @apply text-gray-600;
  @apply leading-relaxed;
}

.left{
  @apply m-8;
  @apply sm:ml-5;
  @apply sm:mr-5;
}

.right{
  @apply m-8;
  @apply sm:mr-5;
  @apply sm:ml-5;
}

.grid-container {
  display: grid;
  grid-template-columns: auto auto auto;
}

@media screen and (max-width: 1024px) {
  .grid-container {
    display: grid;
    grid-template-columns: auto auto;
  }
}

@media screen and (max-width: 750px) {
  .grid-container {
    grid-template-columns: auto;
  }
}

.photo{
  @apply border-8;
  @apply border-neutral-50;
}

.h-18{
  height: 5rem;
}

p.telephone a {
  @apply text-2xl;
  @apply text-sky-600;
  @apply font-bold;
}

.title{
  text-align: center;
  @apply p-4;
}
.reveal{
  position: relative;
  transform: translateY(150px);
  opacity: 0;
  transition: 1s all ease;
}

.reveal.active{
  transform: translateY(0);
  opacity: 1;
}

label{
  @apply text-gray-500;
}

#facilities i{
  font-size:40px;
}

.icon{
  @apply p-4;

}

.icon-text{

}

@media screen and (min-width: 1024px) {
  .half{
    width:50%;
  }
}

////////*********  Concrete5  ************///////



.ccm-toolbar-search {
    display: none;
}
body.admin .ccm-toolbar-search {
    display: block;
}

[data-guide-toolbar-action="sitemap"] {
    display: none;
}
body.admin [data-guide-toolbar-action="sitemap"]{
    display: block;
}

[data-guide-toolbar-action="add-content"]{
    display: none;
}
body.admin [data-guide-toolbar-action="add-content"]{
    display: block;
}

