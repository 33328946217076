@font-face {
	font-family: 'Source Sans Pro', sans-serif;
    src:url("../font/SourceSansPro-Light.otf") format('otf'),
		url("../font/SourceSansPro-Light.woff2") format('woff2'),
        url("../font/SourceSansPro-Light.woff") format('woff'),
        url("../font/SourceSansPro-Light.ttf") format("truetype");
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}
@font-face {
	font-family: 'Source Sans Pro', sans-serif;
	src:url("../font/SourceSansPro-Regular.woff2") format('woff2'),
		url("../font/SourceSansPro-Regular.woff") format('woff'),
		url("../font/SourceSansPro-Regular.ttf") format("truetype");
	font-weight: normal;
	font-style: normal;
	font-display: swap;
}
@font-face {
	font-family: 'Source Sans Pro', sans-serif;
	src:url("../font/SourceSansPro-Bold.woff2") format('woff2'),
		url("../font/SourceSansPro-Bold.woff") format('woff'),
		url("../font/SourceSansPro-Bold.ttf") format("truetype");
	font-weight: normal;
	font-style: normal;
	font-display: swap;
}


// ICON FONT

@font-face {
	font-family: "Flaticon";
	src: url("../font/Flaticon.eot");
	src: url("../font/Flaticon.eot?#iefix") format("embedded-opentype"),
	url("../font/Flaticon.woff2") format("woff2"),
	url("../font/Flaticon.woff") format("woff"),
	url("../font/Flaticon.ttf") format("truetype"),
	url("../font/Flaticon.svg#Flaticon") format("svg");
	font-weight: normal;
	font-style: normal;
}

@media screen and (-webkit-min-device-pixel-ratio:0) {
	@font-face {
		font-family: "Flaticon";
		src: url("../font/Flaticon.svg#Flaticon") format("svg");
	}
}

[class^="flaticon-"]:before, [class*=" flaticon-"]:before,
[class^="flaticon-"]:after, [class*=" flaticon-"]:after {
	font-family: Flaticon;
	//font-size: 20px;
	font-style: normal;
	//margin-left: 20px;
}

.flaticon-screen65:before { content: "\f100"; }
.flaticon-mouse54:before { content: "\f101"; }
.flaticon-twitter-button:before { content: "\f102"; }
.flaticon-logotype3:before { content: "\f103"; }
.flaticon-info28:before { content: "\f104"; }
.flaticon-shower15:before { content: "\f105"; }
.flaticon-tag76:before { content: "\f106"; }
.flaticon-feeding1:before { content: "\f107"; }
.flaticon-sofa10:before { content: "\f108"; }
.flaticon-tag77:before { content: "\f109"; }
.flaticon-computer190:before { content: "\f10a"; }
.flaticon-teapot:before { content: "\f10b"; }
.flaticon-cradle:before { content: "\f10c"; }
.flaticon-home168:before { content: "\f10d"; }
.flaticon-buggy:before { content: "\f10e"; }
.flaticon-direction:before { content: "\f10f"; }
.flaticon-ice:before { content: "\f110"; }
.flaticon-stretching-exercises:before { content: "\f111"; }
.flaticon-bathroom27:before { content: "\f112"; }
.flaticon-phone21:before { content: "\f113"; }
.flaticon-cigarette:before { content: "\f114"; }
.flaticon-hot-drink2:before { content: "\f115"; }
.flaticon-smartphone-call:before { content: "\f116"; }
.flaticon-iron4:before { content: "\f117"; }
.flaticon-iron5:before { content: "\f118"; }
.flaticon-help27:before { content: "\f119"; }
.flaticon-bread29:before { content: "\f11a"; }
.flaticon-drink156:before { content: "\f11b"; }
.flaticon-coin3:before { content: "\f11c"; }
.flaticon-childhood:before { content: "\f11d"; }
.flaticon-social-media10:before { content: "\f11e"; }
.flaticon-price-tag3:before { content: "\f11f"; }
.flaticon-tv12:before { content: "\f120"; }
.flaticon-credit31:before { content: "\f121"; }
.flaticon-flower18:before { content: "\f122"; }
.flaticon-baby92:before { content: "\f123"; }
.flaticon-man168:before { content: "\f124"; }
.flaticon-baby-feeding-chair-variant:before { content: "\f125"; }
.flaticon-leaf32:before { content: "\f126"; }
.flaticon-link16:before { content: "\f127"; }
.flaticon-towel:before { content: "\f128"; }
.flaticon-map32:before { content: "\f129"; }
.flaticon-signpost3:before { content: "\f12a"; }
.flaticon-family11:before { content: "\f12b"; }
.flaticon-coffee26:before { content: "\f12c"; }
.flaticon-plate7:before { content: "\f12d"; }
.flaticon-twitter24:before { content: "\f12e"; }
.flaticon-twitter25:before { content: "\f12f"; }
.flaticon-black218:before { content: "\f130"; }
.flaticon-camping5:before { content: "\f131"; }
.flaticon-hot51:before { content: "\f132"; }
.flaticon-google43:before { content: "\f133"; }
.flaticon-cocktail:before { content: "\f134"; }
.flaticon-key105:before { content: "\f135"; }
.flaticon-mother9:before { content: "\f136"; }
.flaticon-photo137:before { content: "\f137"; }
.flaticon-four64:before { content: "\f138"; }
.flaticon-restaurant26:before { content: "\f139"; }
.flaticon-cutlery6:before { content: "\f13a"; }
.flaticon-kitchen26:before { content: "\f13b"; }
.flaticon-plate13:before { content: "\f13c"; }
.flaticon-glass35:before { content: "\f13d"; }
.flaticon-serving1:before { content: "\f13e"; }
.flaticon-link39:before { content: "\f13f"; }
.flaticon-train-front:before { content: "\f140"; }
.flaticon-computer147:before { content: "\f141"; }
.flaticon-coffee50:before { content: "\f142"; }
.flaticon-furniture:before { content: "\f143"; }
.flaticon-bathroom13:before { content: "\f144"; }
.flaticon-bathtub3:before { content: "\f145"; }
.flaticon-vacuum6:before { content: "\f146"; }
.flaticon-google110:before { content: "\f147"; }
.flaticon-twitter39:before { content: "\f148"; }
.flaticon-tripadvisor-logotype:before { content: "\f149"; }
.flaticon-google111:before { content: "\f14a"; }
.flaticon-swimming:before { content: "\f14b"; }
.flaticon-businessman216:before { content: "\f14c"; }
.flaticon-parking12:before { content: "\f14d"; }
.flaticon-bowl12:before { content: "\f14e"; }
.flaticon-new70:before { content: "\f14f"; }
.flaticon-dark:before { content: "\f150"; }
.flaticon-disabled3:before { content: "\f151"; }
.flaticon-family29:before { content: "\f152"; }
.flaticon-broccoli:before { content: "\f153"; }
.flaticon-facebook53:before { content: "\f154"; }
.flaticon-protected-credit-card:before { content: "\f155"; }
.flaticon-snacks1:before { content: "\f156"; }
.flaticon-swimming-silhouette:before { content: "\f157"; }
.flaticon-cocktail-with-lemon-slice:before { content: "\f158"; }
.flaticon-check-box:before { content: "\f159"; }
.flaticon-parking19:before { content: "\f15a"; }
.flaticon-alarm54:before { content: "\f15b"; }
.flaticon-credit-card8:before { content: "\f15c"; }
.flaticon-camera89:before { content: "\f15d"; }
.flaticon-bed29:before { content: "\f15e"; }
.flaticon-towel4:before { content: "\f15f"; }
.flaticon-restaurant50:before { content: "\f160"; }
.flaticon-silhouette79:before { content: "\f161"; }
.flaticon-user168:before { content: "\f162"; }
.flaticon-print48:before { content: "\f163"; }
.flaticon-technology:before { content: "\f164"; }
.flaticon-ice89:before { content: "\f165"; }
.flaticon-touristic1:before { content: "\f166"; }
.flaticon-underground:before { content: "\f167"; }
.flaticon-camping8:before { content: "\f168"; }
.flaticon-mobile:before { content: "\f169"; }
.flaticon-vegan:before { content: "\f16a"; }
.flaticon-drink87:before { content: "\f16b"; }
.flaticon-car189:before { content: "\f16c"; }
.flaticon-raw-fish:before { content: "\f16d"; }
.flaticon-radio-box2:before { content: "\f16e"; }
.flaticon-wifi102:before { content: "\f16f"; }
.flaticon-stairs12:before { content: "\f170"; }
.flaticon-tv31:before { content: "\f171"; }
.flaticon-key195:before { content: "\f172"; }
.flaticon-heart13:before { content: "\f173"; }
.flaticon-key197:before { content: "\f174"; }
.flaticon-sound-waves:before { content: "\f175"; }
.flaticon-tag79:before { content: "\f176"; }
.flaticon-monitor2:before { content: "\f177"; }
.flaticon-shop14:before { content: "\f178"; }
.flaticon-coffee-shop:before { content: "\f179"; }
.flaticon-credit-card3:before { content: "\f17a"; }
.flaticon-tent:before { content: "\f17b"; }
.flaticon-cycling:before { content: "\f17c"; }
.flaticon-walk16:before { content: "\f17d"; }
.flaticon-price-tag1:before { content: "\f17e"; }
.flaticon-bears1:before { content: "\f17f"; }
.flaticon-bed51:before { content: "\f180"; }
.flaticon-camping1:before { content: "\f181"; }
.flaticon-dog99:before { content: "\f182"; }
.flaticon-car223:before { content: "\f183"; }
.flaticon-breakfast7:before { content: "\f184"; }
.flaticon-hair-salon9:before { content: "\f185"; }
.flaticon-vacation:before { content: "\f186"; }
.flaticon-ingredient1:before { content: "\f187"; }
.flaticon-recepcionist:before { content: "\f188"; }
.flaticon-washing-machine3:before { content: "\f189"; }
.flaticon-eggs7:before { content: "\f18a"; }
.flaticon-road-sign19:before { content: "\f18b"; }
.flaticon-eggs12:before { content: "\f18c"; }
.flaticon-televisions6:before { content: "\f18d"; }
.flaticon-social-network189:before { content: "\f18e"; }
.flaticon-social-network195:before { content: "\f18f"; }
.flaticon-shapes:before { content: "\f190"; }
.flaticon-garden68:before { content: "\f191"; }
.flaticon-briefcase98:before { content: "\f192"; }
.flaticon-relax21:before { content: "\f193"; }
.flaticon-relax22:before { content: "\f194"; }
.flaticon-washing46:before { content: "\f195"; }
.flaticon-petals3:before { content: "\f196"; }
.flaticon-placeholder58:before { content: "\f197"; }
.flaticon-construction72:before { content: "\f198"; }
.flaticon-bath21:before { content: "\f199"; }
.flaticon-bathroom:before { content: "\f19a"; }
.flaticon-bathroom-1:before { content: "\f19b"; }
.flaticon-dishwasher:before { content: "\f19c"; }
.flaticon-socket:before { content: "\f19d"; }
.flaticon-alarm:before { content: "\f19e"; }
.flaticon-maps-and-flags:before { content: "\f19f"; }
.flaticon-electric-station:before { content: "\f1a0"; }
.flaticon-instagram:before { content: "\f1a1"; }
.flaticon-oven-electric-tool:before { content: "\f1a2"; }
.flaticon-volume-level:before { content: "\f1a3"; }
.flaticon-fridge:before { content: "\f1a4"; }
.flaticon-netflix-1:before { content: "\f1a5"; }
.flaticon-speaker:before { content: "\f1a6"; }
.flaticon-speaker-1:before { content: "\f1a7"; }
.flaticon-cappuccino:before { content: "\f1a8"; }
.flaticon-coffee-machine:before { content: "\f1a9"; }
.flaticon-cover:before { content: "\f1aa"; }
