@tailwind base;
@tailwind components;
@tailwind utilities;
@font-face {
  font-family: "Source Sans Pro", sans-serif;
  src: url("../font/SourceSansPro-Light.otf") format("otf"), url("../font/SourceSansPro-Light.woff2") format("woff2"), url("../font/SourceSansPro-Light.woff") format("woff"), url("../font/SourceSansPro-Light.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Source Sans Pro", sans-serif;
  src: url("../font/SourceSansPro-Regular.woff2") format("woff2"), url("../font/SourceSansPro-Regular.woff") format("woff"), url("../font/SourceSansPro-Regular.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Source Sans Pro", sans-serif;
  src: url("../font/SourceSansPro-Bold.woff2") format("woff2"), url("../font/SourceSansPro-Bold.woff") format("woff"), url("../font/SourceSansPro-Bold.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Flaticon";
  src: url("../font/Flaticon.eot");
  src: url("../font/Flaticon.eot?#iefix") format("embedded-opentype"), url("../font/Flaticon.woff2") format("woff2"), url("../font/Flaticon.woff") format("woff"), url("../font/Flaticon.ttf") format("truetype"), url("../font/Flaticon.svg#Flaticon") format("svg");
  font-weight: normal;
  font-style: normal;
}
@media screen and (-webkit-min-device-pixel-ratio: 0) {
  @font-face {
    font-family: "Flaticon";
    src: url("../font/Flaticon.svg#Flaticon") format("svg");
  }
}
[class^=flaticon-]:before, [class*=" flaticon-"]:before,
[class^=flaticon-]:after, [class*=" flaticon-"]:after {
  font-family: Flaticon;
  font-style: normal;
}

.flaticon-screen65:before {
  content: "\f100";
}

.flaticon-mouse54:before {
  content: "\f101";
}

.flaticon-twitter-button:before {
  content: "\f102";
}

.flaticon-logotype3:before {
  content: "\f103";
}

.flaticon-info28:before {
  content: "\f104";
}

.flaticon-shower15:before {
  content: "\f105";
}

.flaticon-tag76:before {
  content: "\f106";
}

.flaticon-feeding1:before {
  content: "\f107";
}

.flaticon-sofa10:before {
  content: "\f108";
}

.flaticon-tag77:before {
  content: "\f109";
}

.flaticon-computer190:before {
  content: "\f10a";
}

.flaticon-teapot:before {
  content: "\f10b";
}

.flaticon-cradle:before {
  content: "\f10c";
}

.flaticon-home168:before {
  content: "\f10d";
}

.flaticon-buggy:before {
  content: "\f10e";
}

.flaticon-direction:before {
  content: "\f10f";
}

.flaticon-ice:before {
  content: "\f110";
}

.flaticon-stretching-exercises:before {
  content: "\f111";
}

.flaticon-bathroom27:before {
  content: "\f112";
}

.flaticon-phone21:before {
  content: "\f113";
}

.flaticon-cigarette:before {
  content: "\f114";
}

.flaticon-hot-drink2:before {
  content: "\f115";
}

.flaticon-smartphone-call:before {
  content: "\f116";
}

.flaticon-iron4:before {
  content: "\f117";
}

.flaticon-iron5:before {
  content: "\f118";
}

.flaticon-help27:before {
  content: "\f119";
}

.flaticon-bread29:before {
  content: "\f11a";
}

.flaticon-drink156:before {
  content: "\f11b";
}

.flaticon-coin3:before {
  content: "\f11c";
}

.flaticon-childhood:before {
  content: "\f11d";
}

.flaticon-social-media10:before {
  content: "\f11e";
}

.flaticon-price-tag3:before {
  content: "\f11f";
}

.flaticon-tv12:before {
  content: "\f120";
}

.flaticon-credit31:before {
  content: "\f121";
}

.flaticon-flower18:before {
  content: "\f122";
}

.flaticon-baby92:before {
  content: "\f123";
}

.flaticon-man168:before {
  content: "\f124";
}

.flaticon-baby-feeding-chair-variant:before {
  content: "\f125";
}

.flaticon-leaf32:before {
  content: "\f126";
}

.flaticon-link16:before {
  content: "\f127";
}

.flaticon-towel:before {
  content: "\f128";
}

.flaticon-map32:before {
  content: "\f129";
}

.flaticon-signpost3:before {
  content: "\f12a";
}

.flaticon-family11:before {
  content: "\f12b";
}

.flaticon-coffee26:before {
  content: "\f12c";
}

.flaticon-plate7:before {
  content: "\f12d";
}

.flaticon-twitter24:before {
  content: "\f12e";
}

.flaticon-twitter25:before {
  content: "\f12f";
}

.flaticon-black218:before {
  content: "\f130";
}

.flaticon-camping5:before {
  content: "\f131";
}

.flaticon-hot51:before {
  content: "\f132";
}

.flaticon-google43:before {
  content: "\f133";
}

.flaticon-cocktail:before {
  content: "\f134";
}

.flaticon-key105:before {
  content: "\f135";
}

.flaticon-mother9:before {
  content: "\f136";
}

.flaticon-photo137:before {
  content: "\f137";
}

.flaticon-four64:before {
  content: "\f138";
}

.flaticon-restaurant26:before {
  content: "\f139";
}

.flaticon-cutlery6:before {
  content: "\f13a";
}

.flaticon-kitchen26:before {
  content: "\f13b";
}

.flaticon-plate13:before {
  content: "\f13c";
}

.flaticon-glass35:before {
  content: "\f13d";
}

.flaticon-serving1:before {
  content: "\f13e";
}

.flaticon-link39:before {
  content: "\f13f";
}

.flaticon-train-front:before {
  content: "\f140";
}

.flaticon-computer147:before {
  content: "\f141";
}

.flaticon-coffee50:before {
  content: "\f142";
}

.flaticon-furniture:before {
  content: "\f143";
}

.flaticon-bathroom13:before {
  content: "\f144";
}

.flaticon-bathtub3:before {
  content: "\f145";
}

.flaticon-vacuum6:before {
  content: "\f146";
}

.flaticon-google110:before {
  content: "\f147";
}

.flaticon-twitter39:before {
  content: "\f148";
}

.flaticon-tripadvisor-logotype:before {
  content: "\f149";
}

.flaticon-google111:before {
  content: "\f14a";
}

.flaticon-swimming:before {
  content: "\f14b";
}

.flaticon-businessman216:before {
  content: "\f14c";
}

.flaticon-parking12:before {
  content: "\f14d";
}

.flaticon-bowl12:before {
  content: "\f14e";
}

.flaticon-new70:before {
  content: "\f14f";
}

.flaticon-dark:before {
  content: "\f150";
}

.flaticon-disabled3:before {
  content: "\f151";
}

.flaticon-family29:before {
  content: "\f152";
}

.flaticon-broccoli:before {
  content: "\f153";
}

.flaticon-facebook53:before {
  content: "\f154";
}

.flaticon-protected-credit-card:before {
  content: "\f155";
}

.flaticon-snacks1:before {
  content: "\f156";
}

.flaticon-swimming-silhouette:before {
  content: "\f157";
}

.flaticon-cocktail-with-lemon-slice:before {
  content: "\f158";
}

.flaticon-check-box:before {
  content: "\f159";
}

.flaticon-parking19:before {
  content: "\f15a";
}

.flaticon-alarm54:before {
  content: "\f15b";
}

.flaticon-credit-card8:before {
  content: "\f15c";
}

.flaticon-camera89:before {
  content: "\f15d";
}

.flaticon-bed29:before {
  content: "\f15e";
}

.flaticon-towel4:before {
  content: "\f15f";
}

.flaticon-restaurant50:before {
  content: "\f160";
}

.flaticon-silhouette79:before {
  content: "\f161";
}

.flaticon-user168:before {
  content: "\f162";
}

.flaticon-print48:before {
  content: "\f163";
}

.flaticon-technology:before {
  content: "\f164";
}

.flaticon-ice89:before {
  content: "\f165";
}

.flaticon-touristic1:before {
  content: "\f166";
}

.flaticon-underground:before {
  content: "\f167";
}

.flaticon-camping8:before {
  content: "\f168";
}

.flaticon-mobile:before {
  content: "\f169";
}

.flaticon-vegan:before {
  content: "\f16a";
}

.flaticon-drink87:before {
  content: "\f16b";
}

.flaticon-car189:before {
  content: "\f16c";
}

.flaticon-raw-fish:before {
  content: "\f16d";
}

.flaticon-radio-box2:before {
  content: "\f16e";
}

.flaticon-wifi102:before {
  content: "\f16f";
}

.flaticon-stairs12:before {
  content: "\f170";
}

.flaticon-tv31:before {
  content: "\f171";
}

.flaticon-key195:before {
  content: "\f172";
}

.flaticon-heart13:before {
  content: "\f173";
}

.flaticon-key197:before {
  content: "\f174";
}

.flaticon-sound-waves:before {
  content: "\f175";
}

.flaticon-tag79:before {
  content: "\f176";
}

.flaticon-monitor2:before {
  content: "\f177";
}

.flaticon-shop14:before {
  content: "\f178";
}

.flaticon-coffee-shop:before {
  content: "\f179";
}

.flaticon-credit-card3:before {
  content: "\f17a";
}

.flaticon-tent:before {
  content: "\f17b";
}

.flaticon-cycling:before {
  content: "\f17c";
}

.flaticon-walk16:before {
  content: "\f17d";
}

.flaticon-price-tag1:before {
  content: "\f17e";
}

.flaticon-bears1:before {
  content: "\f17f";
}

.flaticon-bed51:before {
  content: "\f180";
}

.flaticon-camping1:before {
  content: "\f181";
}

.flaticon-dog99:before {
  content: "\f182";
}

.flaticon-car223:before {
  content: "\f183";
}

.flaticon-breakfast7:before {
  content: "\f184";
}

.flaticon-hair-salon9:before {
  content: "\f185";
}

.flaticon-vacation:before {
  content: "\f186";
}

.flaticon-ingredient1:before {
  content: "\f187";
}

.flaticon-recepcionist:before {
  content: "\f188";
}

.flaticon-washing-machine3:before {
  content: "\f189";
}

.flaticon-eggs7:before {
  content: "\f18a";
}

.flaticon-road-sign19:before {
  content: "\f18b";
}

.flaticon-eggs12:before {
  content: "\f18c";
}

.flaticon-televisions6:before {
  content: "\f18d";
}

.flaticon-social-network189:before {
  content: "\f18e";
}

.flaticon-social-network195:before {
  content: "\f18f";
}

.flaticon-shapes:before {
  content: "\f190";
}

.flaticon-garden68:before {
  content: "\f191";
}

.flaticon-briefcase98:before {
  content: "\f192";
}

.flaticon-relax21:before {
  content: "\f193";
}

.flaticon-relax22:before {
  content: "\f194";
}

.flaticon-washing46:before {
  content: "\f195";
}

.flaticon-petals3:before {
  content: "\f196";
}

.flaticon-placeholder58:before {
  content: "\f197";
}

.flaticon-construction72:before {
  content: "\f198";
}

.flaticon-bath21:before {
  content: "\f199";
}

.flaticon-bathroom:before {
  content: "\f19a";
}

.flaticon-bathroom-1:before {
  content: "\f19b";
}

.flaticon-dishwasher:before {
  content: "\f19c";
}

.flaticon-socket:before {
  content: "\f19d";
}

.flaticon-alarm:before {
  content: "\f19e";
}

.flaticon-maps-and-flags:before {
  content: "\f19f";
}

.flaticon-electric-station:before {
  content: "\f1a0";
}

.flaticon-instagram:before {
  content: "\f1a1";
}

.flaticon-oven-electric-tool:before {
  content: "\f1a2";
}

.flaticon-volume-level:before {
  content: "\f1a3";
}

.flaticon-fridge:before {
  content: "\f1a4";
}

.flaticon-netflix-1:before {
  content: "\f1a5";
}

.flaticon-speaker:before {
  content: "\f1a6";
}

.flaticon-speaker-1:before {
  content: "\f1a7";
}

.flaticon-cappuccino:before {
  content: "\f1a8";
}

.flaticon-coffee-machine:before {
  content: "\f1a9";
}

.flaticon-cover:before {
  content: "\f1aa";
}

fieldset {
  padding: 0;
  margin: 0;
  border: 0;
  min-width: 0;
}

legend {
  display: block;
  width: 100%;
  padding: 0;
  margin-bottom: 20px;
  font-size: 21px;
  line-height: inherit;
  color: #333333;
  border: 0;
  border-bottom: 1px solid #e5e5e5;
}

label {
  display: inline-block;
  max-width: 100%;
  margin-bottom: 5px;
  font-weight: bold;
}

input[type=search] {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

input[type=radio],
input[type=checkbox] {
  margin: 4px 0 0;
  margin-top: 1px \9 ;
  line-height: normal;
}

input[type=file] {
  display: block;
}

input[type=range] {
  display: block;
  width: 100%;
}

select[multiple],
select[size] {
  height: auto;
}

input[type=file]:focus,
input[type=radio]:focus,
input[type=checkbox]:focus {
  outline: thin dotted;
  outline: 5px auto -webkit-focus-ring-color;
  outline-offset: -2px;
}

output {
  display: block;
  padding-top: 11px;
  font-size: 14px;
  line-height: 1.42857;
  color: #555555;
}

.form-control {
  display: block;
  width: 100%;
  height: 42px;
  padding: 10px 20px;
  font-size: 14px;
  line-height: 1.42857;
  color: #555555;
  background-color: #fff;
  background-image: none;
  border: 1px solid #ccc;
  -webkit-border-radius: 4px;
  border-radius: 4px;
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
  -webkit-transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
  -o-transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
  -webkit-transition: border-color ease-in-out 0.15s, -webkit-box-shadow ease-in-out 0.15s;
  transition: border-color ease-in-out 0.15s, -webkit-box-shadow ease-in-out 0.15s;
  transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
  transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s, -webkit-box-shadow ease-in-out 0.15s;
}

.form-control:focus {
  border-color: #66afe9;
  outline: 0;
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px rgba(102, 175, 233, 0.6);
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px rgba(102, 175, 233, 0.6);
}

.form-control::-moz-placeholder {
  color: #999;
  opacity: 1;
}

.form-control:-ms-input-placeholder {
  color: #999;
}

.form-control::-webkit-input-placeholder {
  color: #999;
}

.form-control[disabled], .form-control[readonly],
fieldset[disabled] .form-control {
  background-color: #eeeeee;
  opacity: 1;
}

.form-control[disabled],
fieldset[disabled] .form-control {
  cursor: not-allowed;
}

textarea.form-control {
  height: auto;
}

input[type=search] {
  -webkit-appearance: none;
}

@media screen and (-webkit-min-device-pixel-ratio: 0) {
  input[type=date].form-control,
input[type=time].form-control,
input[type=datetime-local].form-control,
input[type=month].form-control {
    line-height: 42px;
  }

  input[type=date].input-sm, .input-group-sm > input[type=date].form-control,
.input-group-sm > input[type=date].input-group-addon,
.input-group-sm > .input-group-btn > input[type=date].btn,
.input-group-sm input[type=date],
input[type=time].input-sm,
.input-group-sm > input[type=time].form-control,
.input-group-sm > input[type=time].input-group-addon,
.input-group-sm > .input-group-btn > input[type=time].btn,
.input-group-sm input[type=time],
input[type=datetime-local].input-sm,
.input-group-sm > input[type=datetime-local].form-control,
.input-group-sm > input[type=datetime-local].input-group-addon,
.input-group-sm > .input-group-btn > input[type=datetime-local].btn,
.input-group-sm input[type=datetime-local],
input[type=month].input-sm,
.input-group-sm > input[type=month].form-control,
.input-group-sm > input[type=month].input-group-addon,
.input-group-sm > .input-group-btn > input[type=month].btn,
.input-group-sm input[type=month] {
    line-height: 30px;
  }

  input[type=date].input-lg, .input-group-lg > input[type=date].form-control,
.input-group-lg > input[type=date].input-group-addon,
.input-group-lg > .input-group-btn > input[type=date].btn,
.input-group-lg input[type=date],
input[type=time].input-lg,
.input-group-lg > input[type=time].form-control,
.input-group-lg > input[type=time].input-group-addon,
.input-group-lg > .input-group-btn > input[type=time].btn,
.input-group-lg input[type=time],
input[type=datetime-local].input-lg,
.input-group-lg > input[type=datetime-local].form-control,
.input-group-lg > input[type=datetime-local].input-group-addon,
.input-group-lg > .input-group-btn > input[type=datetime-local].btn,
.input-group-lg input[type=datetime-local],
input[type=month].input-lg,
.input-group-lg > input[type=month].form-control,
.input-group-lg > input[type=month].input-group-addon,
.input-group-lg > .input-group-btn > input[type=month].btn,
.input-group-lg input[type=month] {
    line-height: 46px;
  }
}
.form-group {
  margin-bottom: 15px;
}

.radio,
.checkbox {
  position: relative;
  display: block;
  margin-top: 10px;
  margin-bottom: 10px;
}

.radio label,
.checkbox label {
  min-height: 20px;
  padding-left: 20px;
  margin-bottom: 0;
  font-weight: normal;
  cursor: pointer;
}

.radio input[type=radio],
.radio-inline input[type=radio],
.checkbox input[type=checkbox],
.checkbox-inline input[type=checkbox] {
  position: absolute;
  margin-left: -20px;
  margin-top: 4px \9 ;
}

.radio + .radio,
.checkbox + .checkbox {
  margin-top: -5px;
}

.radio-inline,
.checkbox-inline {
  position: relative;
  display: inline-block;
  padding-left: 20px;
  margin-bottom: 0;
  vertical-align: middle;
  font-weight: normal;
  cursor: pointer;
}

.radio-inline + .radio-inline,
.checkbox-inline + .checkbox-inline {
  margin-top: 0;
  margin-left: 10px;
}

input[type=radio][disabled], input[type=radio].disabled,
fieldset[disabled] input[type=radio],
input[type=checkbox][disabled],
input[type=checkbox].disabled,
fieldset[disabled] input[type=checkbox] {
  cursor: not-allowed;
}

.radio-inline.disabled,
fieldset[disabled] .radio-inline,
.checkbox-inline.disabled,
fieldset[disabled] .checkbox-inline {
  cursor: not-allowed;
}

.radio.disabled label,
fieldset[disabled] .radio label,
.checkbox.disabled label,
fieldset[disabled] .checkbox label {
  cursor: not-allowed;
}

.form-control-static {
  padding-top: 11px;
  padding-bottom: 11px;
  margin-bottom: 0;
  min-height: 34px;
}

.form-control-static.input-lg, .input-group-lg > .form-control-static.form-control,
.input-group-lg > .form-control-static.input-group-addon,
.input-group-lg > .input-group-btn > .form-control-static.btn, .form-control-static.input-sm, .input-group-sm > .form-control-static.form-control,
.input-group-sm > .form-control-static.input-group-addon,
.input-group-sm > .input-group-btn > .form-control-static.btn {
  padding-left: 0;
  padding-right: 0;
}

.input-sm, .input-group-sm > .form-control,
.input-group-sm > .input-group-addon,
.input-group-sm > .input-group-btn > .btn {
  height: 30px;
  padding: 5px 20px;
  font-size: 12px;
  line-height: 1.5;
  -webkit-border-radius: 3px;
  border-radius: 3px;
}

select.input-sm, .input-group-sm > select.form-control,
.input-group-sm > select.input-group-addon,
.input-group-sm > .input-group-btn > select.btn {
  height: 30px;
  line-height: 30px;
}

textarea.input-sm, .input-group-sm > textarea.form-control,
.input-group-sm > textarea.input-group-addon,
.input-group-sm > .input-group-btn > textarea.btn,
select[multiple].input-sm,
.input-group-sm > select[multiple].form-control,
.input-group-sm > select[multiple].input-group-addon,
.input-group-sm > .input-group-btn > select[multiple].btn {
  height: auto;
}

.form-group-sm .form-control {
  height: 30px;
  padding: 5px 20px;
  font-size: 12px;
  line-height: 1.5;
  -webkit-border-radius: 3px;
  border-radius: 3px;
}

.form-group-sm select.form-control {
  height: 30px;
  line-height: 30px;
}

.form-group-sm textarea.form-control,
.form-group-sm select[multiple].form-control {
  height: auto;
}

.form-group-sm .form-control-static {
  height: 30px;
  min-height: 32px;
  padding: 6px 20px;
  font-size: 12px;
  line-height: 1.5;
}

.input-lg, .input-group-lg > .form-control,
.input-group-lg > .input-group-addon,
.input-group-lg > .input-group-btn > .btn {
  height: 46px;
  padding: 10px 20px;
  font-size: 18px;
  line-height: 1.33333;
  -webkit-border-radius: 6px;
  border-radius: 6px;
}

select.input-lg, .input-group-lg > select.form-control,
.input-group-lg > select.input-group-addon,
.input-group-lg > .input-group-btn > select.btn {
  height: 46px;
  line-height: 46px;
}

textarea.input-lg, .input-group-lg > textarea.form-control,
.input-group-lg > textarea.input-group-addon,
.input-group-lg > .input-group-btn > textarea.btn,
select[multiple].input-lg,
.input-group-lg > select[multiple].form-control,
.input-group-lg > select[multiple].input-group-addon,
.input-group-lg > .input-group-btn > select[multiple].btn {
  height: auto;
}

.form-group-lg .form-control {
  height: 46px;
  padding: 10px 20px;
  font-size: 18px;
  line-height: 1.33333;
  -webkit-border-radius: 6px;
  border-radius: 6px;
}

.form-group-lg select.form-control {
  height: 46px;
  line-height: 46px;
}

.form-group-lg textarea.form-control,
.form-group-lg select[multiple].form-control {
  height: auto;
}

.form-group-lg .form-control-static {
  height: 46px;
  min-height: 38px;
  padding: 11px 20px;
  font-size: 18px;
  line-height: 1.33333;
}

.has-feedback {
  position: relative;
}

.has-feedback .form-control {
  padding-right: 52.5px;
}

.form-control-feedback {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 2;
  display: block;
  width: 42px;
  height: 42px;
  line-height: 42px;
  text-align: center;
  pointer-events: none;
}

.input-lg + .form-control-feedback, .input-group-lg > .form-control + .form-control-feedback,
.input-group-lg > .input-group-addon + .form-control-feedback,
.input-group-lg > .input-group-btn > .btn + .form-control-feedback,
.input-group-lg + .form-control-feedback,
.form-group-lg .form-control + .form-control-feedback {
  width: 46px;
  height: 46px;
  line-height: 46px;
}

.input-sm + .form-control-feedback, .input-group-sm > .form-control + .form-control-feedback,
.input-group-sm > .input-group-addon + .form-control-feedback,
.input-group-sm > .input-group-btn > .btn + .form-control-feedback,
.input-group-sm + .form-control-feedback,
.form-group-sm .form-control + .form-control-feedback {
  width: 30px;
  height: 30px;
  line-height: 30px;
}

.has-success .help-block,
.has-success .control-label,
.has-success .radio,
.has-success .checkbox,
.has-success .radio-inline,
.has-success .checkbox-inline,
.has-success.radio label,
.has-success.checkbox label,
.has-success.radio-inline label,
.has-success.checkbox-inline label {
  color: #3c763d;
}

.has-success .form-control {
  border-color: #3c763d;
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
}

.has-success .form-control:focus {
  border-color: #2b542c;
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 6px #67b168;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 6px #67b168;
}

.has-success .input-group-addon {
  color: #3c763d;
  border-color: #3c763d;
  background-color: #dff0d8;
}

.has-success .form-control-feedback {
  color: #3c763d;
}

.has-warning .help-block,
.has-warning .control-label,
.has-warning .radio,
.has-warning .checkbox,
.has-warning .radio-inline,
.has-warning .checkbox-inline,
.has-warning.radio label,
.has-warning.checkbox label,
.has-warning.radio-inline label,
.has-warning.checkbox-inline label {
  color: #8a6d3b;
}

.has-warning .form-control {
  border-color: #8a6d3b;
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
}

.has-warning .form-control:focus {
  border-color: #66512c;
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 6px #c0a16b;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 6px #c0a16b;
}

.has-warning .input-group-addon {
  color: #8a6d3b;
  border-color: #8a6d3b;
  background-color: #fcf8e3;
}

.has-warning .form-control-feedback {
  color: #8a6d3b;
}

.has-error .help-block,
.has-error .control-label,
.has-error .radio,
.has-error .checkbox,
.has-error .radio-inline,
.has-error .checkbox-inline,
.has-error.radio label,
.has-error.checkbox label,
.has-error.radio-inline label,
.has-error.checkbox-inline label {
  color: #a94442;
}

.has-error .form-control {
  border-color: #a94442;
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
}

.has-error .form-control:focus {
  border-color: #843534;
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 6px #ce8483;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 6px #ce8483;
}

.has-error .input-group-addon {
  color: #a94442;
  border-color: #a94442;
  background-color: #f2dede;
}

.has-error .form-control-feedback {
  color: #a94442;
}

.has-feedback label ~ .form-control-feedback {
  top: 25px;
}

.has-feedback label.sr-only ~ .form-control-feedback {
  top: 0;
}

.help-block {
  display: block;
  margin-top: 5px;
  margin-bottom: 10px;
  color: #737373;
}

@media (min-width: 768px) {
  .form-inline .form-group {
    display: inline-block;
    margin-bottom: 0;
    vertical-align: middle;
  }

  .form-inline .form-control {
    display: inline-block;
    width: auto;
    vertical-align: middle;
  }

  .form-inline .form-control-static {
    display: inline-block;
  }

  .form-inline .input-group {
    display: inline-table;
    vertical-align: middle;
  }

  .form-inline .input-group .input-group-addon,
.form-inline .input-group .input-group-btn,
.form-inline .input-group .form-control {
    width: auto;
  }

  .form-inline .input-group > .form-control {
    width: 100%;
  }

  .form-inline .control-label {
    margin-bottom: 0;
    vertical-align: middle;
  }

  .form-inline .radio,
.form-inline .checkbox {
    display: inline-block;
    margin-top: 0;
    margin-bottom: 0;
    vertical-align: middle;
  }

  .form-inline .radio label,
.form-inline .checkbox label {
    padding-left: 0;
  }

  .form-inline .radio input[type=radio],
.form-inline .checkbox input[type=checkbox] {
    position: relative;
    margin-left: 0;
  }

  .form-inline .has-feedback .form-control-feedback {
    top: 0;
  }
}
.form-horizontal .radio,
.form-horizontal .checkbox,
.form-horizontal .radio-inline,
.form-horizontal .checkbox-inline {
  margin-top: 0;
  margin-bottom: 0;
  padding-top: 11px;
}

.form-horizontal .radio,
.form-horizontal .checkbox {
  min-height: 31px;
}

.form-horizontal .form-group {
  margin-left: -15px;
  margin-right: -15px;
}

.form-horizontal .form-group:before, .form-horizontal .form-group:after {
  content: " ";
  display: table;
}

.form-horizontal .form-group:after {
  clear: both;
}

@media (min-width: 768px) {
  .form-horizontal .control-label {
    text-align: right;
    margin-bottom: 0;
    padding-top: 11px;
  }
}
.form-horizontal .has-feedback .form-control-feedback {
  right: 15px;
}

@media (min-width: 768px) {
  .form-horizontal .form-group-lg .control-label {
    padding-top: 14.33333px;
    font-size: 18px;
  }
}
@media (min-width: 768px) {
  .form-horizontal .form-group-sm .control-label {
    padding-top: 6px;
    font-size: 12px;
  }
}
.btn {
  display: inline-block;
  margin-bottom: 0;
  font-weight: normal;
  text-align: center;
  vertical-align: middle;
  -ms-touch-action: manipulation;
  touch-action: manipulation;
  cursor: pointer;
  background-image: none;
  border: 1px solid transparent;
  white-space: nowrap;
  padding: 10px 20px;
  font-size: 14px;
  line-height: 1.42857;
  -webkit-border-radius: 4px;
  border-radius: 4px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.btn:focus, .btn.focus, .btn:active:focus, .btn:active.focus, .btn.active:focus, .btn.active.focus {
  outline: thin dotted;
  outline: 5px auto -webkit-focus-ring-color;
  outline-offset: -2px;
}

.btn:hover, .btn:focus, .btn.focus {
  color: #333;
  text-decoration: none;
}

.btn:active, .btn.active {
  outline: 0;
  background-image: none;
  -webkit-box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
}

.btn.disabled, .btn[disabled],
fieldset[disabled] .btn {
  cursor: not-allowed;
  opacity: 0.65;
  filter: alpha(opacity=65);
  -webkit-box-shadow: none;
  box-shadow: none;
}

a.btn.disabled,
fieldset[disabled] a.btn {
  pointer-events: none;
}

.btn-default {
  color: #333;
  background-color: #fff;
  border-color: #ccc;
}

.btn-default:focus, .btn-default.focus {
  color: #333;
  background-color: #e6e6e6;
  border-color: #8c8c8c;
}

.btn-default:hover {
  color: #333;
  background-color: #e6e6e6;
  border-color: #adadad;
}

.btn-default:active, .btn-default.active,
.open > .btn-default.dropdown-toggle {
  color: #333;
  background-color: #e6e6e6;
  border-color: #adadad;
}

.btn-default:active:hover, .btn-default:active:focus, .btn-default:active.focus, .btn-default.active:hover, .btn-default.active:focus, .btn-default.active.focus,
.open > .btn-default.dropdown-toggle:hover,
.open > .btn-default.dropdown-toggle:focus,
.open > .btn-default.dropdown-toggle.focus {
  color: #333;
  background-color: #d4d4d4;
  border-color: #8c8c8c;
}

.btn-default:active, .btn-default.active,
.open > .btn-default.dropdown-toggle {
  background-image: none;
}

.btn-default.disabled, .btn-default.disabled:hover, .btn-default.disabled:focus, .btn-default.disabled.focus, .btn-default.disabled:active, .btn-default.disabled.active, .btn-default[disabled], .btn-default[disabled]:hover, .btn-default[disabled]:focus, .btn-default[disabled].focus, .btn-default[disabled]:active, .btn-default[disabled].active,
fieldset[disabled] .btn-default,
fieldset[disabled] .btn-default:hover,
fieldset[disabled] .btn-default:focus,
fieldset[disabled] .btn-default.focus,
fieldset[disabled] .btn-default:active,
fieldset[disabled] .btn-default.active {
  background-color: #fff;
  border-color: #ccc;
}

.btn-default .badge {
  color: #fff;
  background-color: #333;
}

.btn-primary {
  color: #fff;
  background-color: #337ab7;
  border-color: #2e6da4;
}

.btn-primary:focus, .btn-primary.focus {
  color: #fff;
  background-color: #286090;
  border-color: #122b40;
}

.btn-primary:hover {
  color: #fff;
  background-color: #286090;
  border-color: #204d74;
}

.btn-primary:active, .btn-primary.active,
.open > .btn-primary.dropdown-toggle {
  color: #fff;
  background-color: #286090;
  border-color: #204d74;
}

.btn-primary:active:hover, .btn-primary:active:focus, .btn-primary:active.focus, .btn-primary.active:hover, .btn-primary.active:focus, .btn-primary.active.focus,
.open > .btn-primary.dropdown-toggle:hover,
.open > .btn-primary.dropdown-toggle:focus,
.open > .btn-primary.dropdown-toggle.focus {
  color: #fff;
  background-color: #204d74;
  border-color: #122b40;
}

.btn-primary:active, .btn-primary.active,
.open > .btn-primary.dropdown-toggle {
  background-image: none;
}

.btn-primary.disabled, .btn-primary.disabled:hover, .btn-primary.disabled:focus, .btn-primary.disabled.focus, .btn-primary.disabled:active, .btn-primary.disabled.active, .btn-primary[disabled], .btn-primary[disabled]:hover, .btn-primary[disabled]:focus, .btn-primary[disabled].focus, .btn-primary[disabled]:active, .btn-primary[disabled].active,
fieldset[disabled] .btn-primary,
fieldset[disabled] .btn-primary:hover,
fieldset[disabled] .btn-primary:focus,
fieldset[disabled] .btn-primary.focus,
fieldset[disabled] .btn-primary:active,
fieldset[disabled] .btn-primary.active {
  background-color: #337ab7;
  border-color: #2e6da4;
}

.btn-primary .badge {
  color: #337ab7;
  background-color: #fff;
}

.btn-success {
  color: #fff;
  background-color: #5cb85c;
  border-color: #4cae4c;
}

.btn-success:focus, .btn-success.focus {
  color: #fff;
  background-color: #449d44;
  border-color: #255625;
}

.btn-success:hover {
  color: #fff;
  background-color: #449d44;
  border-color: #398439;
}

.btn-success:active, .btn-success.active,
.open > .btn-success.dropdown-toggle {
  color: #fff;
  background-color: #449d44;
  border-color: #398439;
}

.btn-success:active:hover, .btn-success:active:focus, .btn-success:active.focus, .btn-success.active:hover, .btn-success.active:focus, .btn-success.active.focus,
.open > .btn-success.dropdown-toggle:hover,
.open > .btn-success.dropdown-toggle:focus,
.open > .btn-success.dropdown-toggle.focus {
  color: #fff;
  background-color: #398439;
  border-color: #255625;
}

.btn-success:active, .btn-success.active,
.open > .btn-success.dropdown-toggle {
  background-image: none;
}

.btn-success.disabled, .btn-success.disabled:hover, .btn-success.disabled:focus, .btn-success.disabled.focus, .btn-success.disabled:active, .btn-success.disabled.active, .btn-success[disabled], .btn-success[disabled]:hover, .btn-success[disabled]:focus, .btn-success[disabled].focus, .btn-success[disabled]:active, .btn-success[disabled].active,
fieldset[disabled] .btn-success,
fieldset[disabled] .btn-success:hover,
fieldset[disabled] .btn-success:focus,
fieldset[disabled] .btn-success.focus,
fieldset[disabled] .btn-success:active,
fieldset[disabled] .btn-success.active {
  background-color: #5cb85c;
  border-color: #4cae4c;
}

.btn-success .badge {
  color: #5cb85c;
  background-color: #fff;
}

.btn-info {
  color: #fff;
  background-color: #5bc0de;
  border-color: #46b8da;
}

.btn-info:focus, .btn-info.focus {
  color: #fff;
  background-color: #31b0d5;
  border-color: #1b6d85;
}

.btn-info:hover {
  color: #fff;
  background-color: #31b0d5;
  border-color: #269abc;
}

.btn-info:active, .btn-info.active,
.open > .btn-info.dropdown-toggle {
  color: #fff;
  background-color: #31b0d5;
  border-color: #269abc;
}

.btn-info:active:hover, .btn-info:active:focus, .btn-info:active.focus, .btn-info.active:hover, .btn-info.active:focus, .btn-info.active.focus,
.open > .btn-info.dropdown-toggle:hover,
.open > .btn-info.dropdown-toggle:focus,
.open > .btn-info.dropdown-toggle.focus {
  color: #fff;
  background-color: #269abc;
  border-color: #1b6d85;
}

.btn-info:active, .btn-info.active,
.open > .btn-info.dropdown-toggle {
  background-image: none;
}

.btn-info.disabled, .btn-info.disabled:hover, .btn-info.disabled:focus, .btn-info.disabled.focus, .btn-info.disabled:active, .btn-info.disabled.active, .btn-info[disabled], .btn-info[disabled]:hover, .btn-info[disabled]:focus, .btn-info[disabled].focus, .btn-info[disabled]:active, .btn-info[disabled].active,
fieldset[disabled] .btn-info,
fieldset[disabled] .btn-info:hover,
fieldset[disabled] .btn-info:focus,
fieldset[disabled] .btn-info.focus,
fieldset[disabled] .btn-info:active,
fieldset[disabled] .btn-info.active {
  background-color: #5bc0de;
  border-color: #46b8da;
}

.btn-info .badge {
  color: #5bc0de;
  background-color: #fff;
}

.btn-warning {
  color: #fff;
  background-color: #f0ad4e;
  border-color: #eea236;
}

.btn-warning:focus, .btn-warning.focus {
  color: #fff;
  background-color: #ec971f;
  border-color: #985f0d;
}

.btn-warning:hover {
  color: #fff;
  background-color: #ec971f;
  border-color: #d58512;
}

.btn-warning:active, .btn-warning.active,
.open > .btn-warning.dropdown-toggle {
  color: #fff;
  background-color: #ec971f;
  border-color: #d58512;
}

.btn-warning:active:hover, .btn-warning:active:focus, .btn-warning:active.focus, .btn-warning.active:hover, .btn-warning.active:focus, .btn-warning.active.focus,
.open > .btn-warning.dropdown-toggle:hover,
.open > .btn-warning.dropdown-toggle:focus,
.open > .btn-warning.dropdown-toggle.focus {
  color: #fff;
  background-color: #d58512;
  border-color: #985f0d;
}

.btn-warning:active, .btn-warning.active,
.open > .btn-warning.dropdown-toggle {
  background-image: none;
}

.btn-warning.disabled, .btn-warning.disabled:hover, .btn-warning.disabled:focus, .btn-warning.disabled.focus, .btn-warning.disabled:active, .btn-warning.disabled.active, .btn-warning[disabled], .btn-warning[disabled]:hover, .btn-warning[disabled]:focus, .btn-warning[disabled].focus, .btn-warning[disabled]:active, .btn-warning[disabled].active,
fieldset[disabled] .btn-warning,
fieldset[disabled] .btn-warning:hover,
fieldset[disabled] .btn-warning:focus,
fieldset[disabled] .btn-warning.focus,
fieldset[disabled] .btn-warning:active,
fieldset[disabled] .btn-warning.active {
  background-color: #f0ad4e;
  border-color: #eea236;
}

.btn-warning .badge {
  color: #f0ad4e;
  background-color: #fff;
}

.btn-danger {
  color: #fff;
  background-color: #d9534f;
  border-color: #d43f3a;
}

.btn-danger:focus, .btn-danger.focus {
  color: #fff;
  background-color: #c9302c;
  border-color: #761c19;
}

.btn-danger:hover {
  color: #fff;
  background-color: #c9302c;
  border-color: #ac2925;
}

.btn-danger:active, .btn-danger.active,
.open > .btn-danger.dropdown-toggle {
  color: #fff;
  background-color: #c9302c;
  border-color: #ac2925;
}

.btn-danger:active:hover, .btn-danger:active:focus, .btn-danger:active.focus, .btn-danger.active:hover, .btn-danger.active:focus, .btn-danger.active.focus,
.open > .btn-danger.dropdown-toggle:hover,
.open > .btn-danger.dropdown-toggle:focus,
.open > .btn-danger.dropdown-toggle.focus {
  color: #fff;
  background-color: #ac2925;
  border-color: #761c19;
}

.btn-danger:active, .btn-danger.active,
.open > .btn-danger.dropdown-toggle {
  background-image: none;
}

.btn-danger.disabled, .btn-danger.disabled:hover, .btn-danger.disabled:focus, .btn-danger.disabled.focus, .btn-danger.disabled:active, .btn-danger.disabled.active, .btn-danger[disabled], .btn-danger[disabled]:hover, .btn-danger[disabled]:focus, .btn-danger[disabled].focus, .btn-danger[disabled]:active, .btn-danger[disabled].active,
fieldset[disabled] .btn-danger,
fieldset[disabled] .btn-danger:hover,
fieldset[disabled] .btn-danger:focus,
fieldset[disabled] .btn-danger.focus,
fieldset[disabled] .btn-danger:active,
fieldset[disabled] .btn-danger.active {
  background-color: #d9534f;
  border-color: #d43f3a;
}

.btn-danger .badge {
  color: #d9534f;
  background-color: #fff;
}

.btn-link {
  color: #337ab7;
  font-weight: normal;
  -webkit-border-radius: 0;
  border-radius: 0;
}

.btn-link, .btn-link:active, .btn-link.active, .btn-link[disabled],
fieldset[disabled] .btn-link {
  background-color: transparent;
  -webkit-box-shadow: none;
  box-shadow: none;
}

.btn-link, .btn-link:hover, .btn-link:focus, .btn-link:active {
  border-color: transparent;
}

.btn-link:hover, .btn-link:focus {
  color: #23527c;
  text-decoration: underline;
  background-color: transparent;
}

.btn-link[disabled]:hover, .btn-link[disabled]:focus,
fieldset[disabled] .btn-link:hover,
fieldset[disabled] .btn-link:focus {
  color: #777777;
  text-decoration: none;
}

.btn-lg, .btn-group-lg > .btn {
  padding: 10px 20px;
  font-size: 18px;
  line-height: 1.33333;
  -webkit-border-radius: 6px;
  border-radius: 6px;
}

.btn-sm, .btn-group-sm > .btn {
  padding: 5px 20px;
  font-size: 12px;
  line-height: 1.5;
  -webkit-border-radius: 3px;
  border-radius: 3px;
}

.btn-xs, .btn-group-xs > .btn {
  padding: 1px 5px;
  font-size: 12px;
  line-height: 1.5;
  -webkit-border-radius: 3px;
  border-radius: 3px;
}

.btn-block {
  display: block;
  width: 100%;
}

.btn-block + .btn-block {
  margin-top: 5px;
}

input[type=submit].btn-block,
input[type=reset].btn-block,
input[type=button].btn-block {
  width: 100%;
}

body {
  font-family: "Source Sans Pro", Arial, sans-serif;
}

#brand {
  @apply uppercase;
  @apply text-sky-600;
  @apply text-3xl;
  @apply font-bold;
  @apply sm:pl-0;
}

@media screen and (max-width: 750px) {
  .mobile-nav {
    justify-content: center;
  }
}
h1 {
  @apply text-gray-700;
  @apply font-light;
  @apply text-4xl;
  @apply ml-6;
  @apply py-4;
}

h2 {
  @apply text-2xl;
  @apply text-gray-700;
  @apply text-center;
  @apply py-4;
  @apply font-light;
}

h3 {
  @apply text-4xl;
  @apply font-light;
  @apply text-gray-800;
  @apply uppercase;
  @apply py-10;
  @apply tracking-tight;
  @apply text-center;
  @apply sm:text-5xl;
}

h3:after {
  background: none repeat scroll 0 0 #0284C7;
  bottom: -20px;
  content: "";
  display: block;
  height: 2px;
  position: relative;
  width: 100px;
  text-align: center;
  margin: auto;
}

nav {
  @apply mb-4;
  @apply lg:mb-0;
  @apply text-gray-600;
  @apply sticky;
  @apply top-0;
  @apply z-10;
}

#establishment {
  @apply text-5xl;
  @apply font-extralight;
  @apply text-gray-800;
  @apply uppercase;
  @apply py-8;
  @apply tracking-tight;
  @apply sm:text-6xl;
}

#establishment:after {
  background: none repeat scroll 0 0 #0284C7;
  bottom: -20px;
  content: "";
  display: block;
  height: 2px;
  position: relative;
  width: 100px;
  text-align: center;
  margin: auto;
}

.spacer {
  padding: 20px 0 60px 0;
}

#main-img {
  @apply pb-8;
  @apply sm:pb-0;
}

main p {
  @apply text-xl;
  @apply font-normal;
}

.justify-text {
  @apply text-justify;
  @apply text-gray-600;
  @apply leading-relaxed;
}

.center-text {
  @apply text-center;
  @apply text-gray-600;
  @apply leading-relaxed;
}

.left {
  @apply m-8;
  @apply sm:ml-5;
  @apply sm:mr-5;
}

.right {
  @apply m-8;
  @apply sm:mr-5;
  @apply sm:ml-5;
}

.grid-container {
  display: grid;
  grid-template-columns: auto auto auto;
}

@media screen and (max-width: 1024px) {
  .grid-container {
    display: grid;
    grid-template-columns: auto auto;
  }
}
@media screen and (max-width: 750px) {
  .grid-container {
    grid-template-columns: auto;
  }
}
.photo {
  @apply border-8;
  @apply border-neutral-50;
}

.h-18 {
  height: 5rem;
}

p.telephone a {
  @apply text-2xl;
  @apply text-sky-600;
  @apply font-bold;
}

.title {
  text-align: center;
  @apply p-4;
}

.reveal {
  position: relative;
  transform: translateY(150px);
  opacity: 0;
  transition: 1s all ease;
}

.reveal.active {
  transform: translateY(0);
  opacity: 1;
}

label {
  @apply text-gray-500;
}

#facilities i {
  font-size: 40px;
}

.icon {
  @apply p-4;
}

@media screen and (min-width: 1024px) {
  .half {
    width: 50%;
  }
}
.ccm-toolbar-search {
  display: none;
}

body.admin .ccm-toolbar-search {
  display: block;
}

[data-guide-toolbar-action=sitemap] {
  display: none;
}

body.admin [data-guide-toolbar-action=sitemap] {
  display: block;
}

[data-guide-toolbar-action=add-content] {
  display: none;
}

body.admin [data-guide-toolbar-action=add-content] {
  display: block;
}